const pages = {
  bio: {
    displayName: 'Perky Pat Bio',
    seo: 'bio',
    description: 'About Perky Perky Pat alias Esteban Almiron'
  },
  contact: {
    displayName: 'Contact',
    seo: 'contact',
    description: 'Contact and social media'
  }
}

export default pages

import React from 'react'

export default function Credits ({ credits }) {
  return (
    <div className='credits'>
      <br />
      {credits}
    </div>
  )
}
